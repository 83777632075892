.header{
	width: 100%;
	min-height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.header .switch{
	letter-spacing: .5em;
	font-size: 15px;
	font-weight: bold;
	font-family: 'Quicksand';
	color: #5d5d5d;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 84px;
	z-index: 1;
}



.header .logo{
	display: none;
}

.wrapper-home .header{
	max-width: 560px;
	position: absolute;
	top: 0px;
	padding-right: 30px;
	left: 0px;
}

body:not(.home) .header{
	position: fixed;
	left: 0px;
	max-width: calc(45%);
	z-index: 3;
}

body:not(.home) .header .switch{
	color: #5d5d5d
}

@media all and (max-width: 1024px) {
	.header, body:not(.home) .header{
		position: fixed !important;
		top: 0px;
		background-color: white;
		z-index: 2;
		width: 100% !important;
		max-width: 100% !important;
	}
	.header .logo{
		display: block;
		max-height: 28px;
    	margin: 0px;
	}
}
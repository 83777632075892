button{
	outline: none;
}
.button.hexagonal{
	width: 148px;
	height: 142px;
	background-image: url('../images/bottonHexagonal.png');
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	margin-left: 15px;
	background-position: center;
	background-repeat: no-repeat;
}

.button.hexagonal .button-available{
	cursor: pointer;
	font-family: 'Quicksand Book';
	color: #000000;
	width:  105px;
	height: 105px;
	margin-left: 13px;
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 17px;
}

.button.hexagonal.big{
	width: 214px;
	height: 205px;
	background-image: url('/images/hexagono-big.png');
}

.button.hexagonal.big .button-available{
	width: 161px;
    height: 153px;
}

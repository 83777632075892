.row.cards{
	max-width: 590px;
	width: 100%;
	flex-wrap: wrap;
	justify-content: space-between;
}
.card{
	display: flex;
	flex-direction: column;
	width: 280px;
	min-height: 360px;
    background: white;
	margin-bottom: 30px;
}

.card .card-header{
	width: 280px;
	height: 225px;
	background-color: #e1bf5c;
}

.card .card-body{
	width: calc(280px - 20px);
	padding: 10px;
	background-color: white;
}

.card .card-body h2{
	margin: 0px;
	font-size: 22px;
	font-family: 'Quicksand';
	font-weight: bold;
	color: black;
	margin-bottom: 10px;
}
.card .card-body span{
	font-family: 'Quicksand Book';
	font-size: 14px;
	color: black !important;
}

@media all and (max-width: 1024px) {
	.row.cards{
		justify-content: center;
	}
}
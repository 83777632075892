:root{ 
  --accent-color: #f5c700;
  --primary-font: 'Quicksand';
  --font-book   : 'Quicksand Book';
}

body{
  padding:0px; 
  margin:0px; 
  background:#fff;
  font-family: 'Quicksand';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.image-carrousel{
  cursor: url("/images/cursor.png") 20 20, auto;
}
body.aviso{
  margin-top: 30px !important;
}


body.join-us .column h1, body.donwload .column h1  , body.colaboradores .column h1 , body.contact .column h1{
  margin-top: 75px;
}

.home-scroll{
  max-height: calc(100vh - 100px);
  overflow: scroll;
}


.aviso-container{
  width: calc(100% - 30px);
  max-width: 1024px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0px auto;
}
.aviso-container table td{
  padding: 5px;
}
.aviso-container h4 , .aviso-container h3{
  text-align: center;
}
.aviso-container p{
  padding-top: 0px;
  margin-top: 0px;
}
.modal-thanks{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 30px;
  border-radius: 3px;
}
.modal-thanks h2, .modal-thanks p{
  text-align: center;
  width: 100%;
}

.modal-thanks button{
  position: absolute;
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  top: -12px;
  right: -12px;
  background-color: black;
}
.modal-thanks button i{
  color: white;
}

.row-column{
  zoom:0.8;
}

/*modal*/
.closebutton{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: black;
  border: 1px solid white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 0px;
}
.closebutton i{
  color: white;
  font-size: 20px;
  line-height: 10px;
  margin: 0px;
  padding: 0px;
}
.ReactModal__Overlay{
  z-index: 999;
  background-color: rgba(0,0,0,0.4) !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ReactModal__Content{
  left: initial !important;
  right: initial !important;
  position: relative !important;
  max-width: 640px;
  display: flex;
  flex-direction: row;
  background-color: transparent !important;
  border: 0px !important;
  align-items: center;
  justify-content: center;
}

h1{
  font-size: 25px;
  font-weight: 400;
  font-family: var(--primary-font);
  color: black;
  margin-bottom: 30px;
}
.column h1{
  color : #000000;
  font-size: 25px;
  letter-spacing: .5em;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  font-family: 'Quicksand Book';
}
img.img-center{
  display: block;
  margin: 0px auto;
}
img.logo{
  margin-bottom: 60px;
  margin-top: 30px;
}
p{
  line-height: 1.3em;
  font-size: 15px;
  color: #010b1b;
  font-family: 'Quicksand Book';
  margin-bottom: 30px;
}
p.description{
  font-family: 'Quicksand Book';
  font-size: 16px;
  letter-spacing: .1rem;
  color: #000000;
  margin-bottom: 60px;
}
p.description b{
  font-style: italic;
}
p.text-center,a.text-center,h1.text-center,h2.text-center{
  text-align: center;
}
a{
  text-decoration: none;
}
a.text-center{
  margin: 0px auto;
  display: block;
}
p b{
  font-family: var(--primary-font);
  font-weight: bold;
}

/*layout*/
.row{
  display: flex;
  flex-direction: row;
}
.container{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  min-height: calc(100vh);
}

.container .column{
  transition: all 600ms ease-in-out;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.container .column:first-child{
  width: 45%;
}
.container .column:last-child{
  width: 55%;
}

.container .bullets-group{
  position: absolute;
  bottom: 10px;
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
}
.container .bullets-group button{
  margin: 10px;
}

body.home .container .column:last-child{
  width: 100%;
}

.container .column.sidebar{
  max-width: 560px;
  min-width: 560px;
  padding-top: 100px;
}

.container .column.center{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.container .column.gradient{
  background-image: linear-gradient(to bottom,#d2c082,#e3ddc7);
}
.container .column-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 60px);
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/images/2019-03-26.jpg');
}

body:not(.home) .column-content{
  background-image: none; 
  padding-top: 0px;
}
body.home{
  overflow: hidden;
}

.right-content .wrapper-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 680px;
}

body:not(.home) .column:last-child{
  background-image: linear-gradient(to bottom,#fefefe,#d1d1d1);
}
/*menu start end*/
#images img{
  width: 100%;
  height: auto;
}

@media all and (max-width: 1024px) {
  /*mobile layout*/
  .container{
    flex-direction: column;
    min-height: auto;
  }
  .container .column{
    width: 100% !important;
    min-height: auto;
    max-width: auto !important;
    min-width: auto !important;
  }
  .container .column.sidebar{
    padding-top: 0px !important;
    max-width: 100% !important;
  }
  body{
    margin-top: 100px;
  }
  body.home .container{
    flex-direction: column-reverse;
  }
  body.home{
    overflow: auto !important;
  }
  #images img{
    max-width: calc(100% - 30px);
    margin: 0px auto;
    display: block;
  }
  .container .column.gradient{
    background-color: transparent ;
    background-image: inherit;
  }
  .image-carrousel:after{
    filter: invert(100%);
    height: 100px !important;
    width: 90% !important;
    margin: 0px auto !important;
  }
  .container .bullets-group{
    bottom: -25px !important;
  }
  .container .column img.logo{
    display: none;
  }
  .container .column-content{
    width: initial;
    padding: 15px;
    max-height: 100% !important;
    overflow: inherit !important;
  }
  body:not(.home) .column:last-child{
    min-height: calc(100vh - 100px);
  }
  .wrapper-menu span{
    display: none;
  }

  .home-scroll{
    max-height: auto !important;
    overflow: hidden;
  }

}
.form-group {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;
}
.form-group label{
  margin-bottom: 5px;
}
.form-group input{
  width: calc(100% - 20px);
  height: 38px;
  border-radius: 5px;
  border:0;
  background-color: white;
}
.form-group textarea{
  width: calc(100% - 20px);
  height: 76px;
  border-radius: 5px;
  border:0;
  background-color: white;
}
.join-us .form-group label{
  font-size: 15px;
  font-family: 'Quicksand';
  font-weight: bold;
}

.contact .form-group label{
  letter-spacing: .1rem;
  font-family: 'Quicksand Book';
  font-size: 16px;
  color: 000000;
}
.form-group textarea, .form-group input{
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0px 1px 1px #fffc00;
  outline: none;
  font-family: var(--primary-font);
}

.item .form-group input{
  height: 22px;
  min-height: 22px;
  max-height: 22px !important;
}
@import './button.css';
@import './slider.css';
@import './menu.css';
@import './card.css';
@import './hamburgers.css';
@import './header.css';

/*menu start*/
.hamburger-inner::before{
  opacity: 0 !important;
}
.wrapper-menu{
  z-index: 99;
  position: relative;
  cursor: pointer;
  border: 0px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wrapper-menu span{
  font-size: 15px;
  font-family: 'Quicksand';
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.3em;
}


.row-column{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}


.column-item{
  display: flex;
  flex-direction: column;
  margin: 5px;
  align-items : center;
}
.column-item .button{
  position: relative;
  z-index: 1;
}
.column-item .item{
  min-height: 400px;
  width: calc(155px);
  max-width: 155px;
  padding: 15px;
  margin-left: -6px;
  margin-top: -110px;
  padding-top: 90px;
  background-image: linear-gradient(to bottom,#fffcdf,transparent);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.responsive-image{
  max-width: calc(100% - 30px);
}

@media all and (max-width: 1024px) {
  .container .column:last-child{
    padding-top: 0px !important;
  }
  .row-column{
    flex-direction: column;
    align-items: center;
  }

  .column-item .item{
    background-image: none;
    width: 100%;
    max-width: calc(100% - 30px);
    height: auto;
    min-height: auto;
  }
  .vimeo{
    width: 100%;
  }
}

button.facebook img{
  margin-right: 10px;
}
button.facebook{
  background-color: #4267b2;
  border: 0px;
  border-radius: 0px;
  color: white;
  font-weight: 700;
  font-family: var(--primary-font);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
button.send:disabled{
  opacity: 0.4;
}
button.send{
  width:auto;
  color: black;
  font-size: 16px;
  letter-spacing: 0.1rem;
  border: 0px;
  border-radius: 5px;
  padding: 5px;
  background-image: linear-gradient(to right, #ffe300,#ffbc00);
  cursor: pointer;
  font-family: 'Quicksand';
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
}
button.send.medium{
  width: auto;
  min-height: 25px;
  font-size: 12px !important;
  color: white; 
}
a.privacy{
  text-decoration: none;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Quicksand Book';
}
.logos{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  min-height: 63px;
  margin-bottom: 15px;
}
.logos img{
  width: 100%;
  margin: 10px;
  max-width: 50px;
  filter: contrast(0) brightness(50%);
}
.logos img.color{
  filter: initial !important;
}
.logos.colaboradores{
  flex-wrap: wrap;
  width: 100%;
  max-width: 300px;
  justify-content: center;
}
.logos.colaboradores a{
  min-width: 100px;
  margin: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}
.logos.colaboradores img{
  min-width: 70px;
  height: auto;
  display: block;
}
.logos.colaboradores img:hover{
  filter: inherit !important;
}

.logos.gray img{
  display: block !important;
}


.image-carrousel{
  position: relative;
}
.image-carrousel:before{
  content: '';
  top: 0px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.image-carrousel:after{
  background-image: url('/images/rotation-img.png');
  background-size: contain;
  content: '';
  position: absolute;
  bottom: 0px;
  height: 170px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  left: 0px;
  right: 0px;
}

@media all and (max-width: 1024px) {
  .logos.colaboradores a{
    min-width: 100px;
  }
  .logos.colaboradores img{
    min-width: 100px ;
    filter: grayscale(0%);  
  }
  .logos{
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  .column h1{
    word-break: break-word;
    word-wrap: break-word;
  }
}
@media all and (max-width: 414px){
  .column h1{
    font-size: 22px;
  }
}

@media all and (max-width: 375px){
  .column h1{
    font-size: 22px;
  }
}
/*css iphone 5*/
@media all and (max-width: 320px){
  .column h1{
    font-size: 20px;
  }
  p.description{
    font-size: 16px;
  }
}


@media all and (max-width: 1024px),  and screen and (max-height: 800px) {
  .scroll-menu{
    margin-top: 0px !important;
  }

}
 
@media all and (max-width: 1024px){
  .row-column{
    zoom:1;
  }
}

@media screen and (max-height: 800px) {
  .scroll-menu{
    margin-top: 100px;
  }
  .menu-item{
    margin-bottom: 30px; 
  }
  .column h1{
    margin-bottom: 30px;
  }
  .form-group input{
    height: 30px;
  }
  p.description{
    margin-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .image-carrousel{
  
    width: 100% !important;
  }
  .container .column-content{
          max-width: calc(100% - 30px) !important;
          width: calc(100% - 30px) !important;
  }
}
.slider {
  max-width: 315px !important;
}
.slider p b.italic{
	font-style: italic;
}
.slider-control-centerright{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	right: -70px !important;
}

.slider-control-bottomcenter{
	bottom: 0px !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.slider-control-bottomcenter button{
	margin: 5px;
}


button.bullet{
	background-color: #c0c0c0;
	border-radius: 100%;
	border: 0px;
	margin-top: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	min-width: 15px;
	min-height: 15px;
	padding: 0px !important;
}

button.bullet.active{
	background-color: var(--accent-color);
	box-shadow: inset -1px -1px 2px #c7aa00;
	min-height: 20px;
	min-width: 20px;
	max-width: 20px;
	max-height: 20px;
	width: 20px;
	height: 20px;
	
}

.slider.carrousel{
	min-width: 100%;
}
.slider.carrousel .slider-slide img{
	width: 100%;
}
.slider.carrousel .slider-slide div.colmena img{
	width: 110%;
	margin-left: -5%;
}

@media all and (max-width: 1024px) {
	.slider.carrousel{
		min-width: 100%;
	}
	.slider-control-centerright{
		right: -20px !important;
	}
}
.menu{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: transform 1s ease-in-out;
	background-image: url('/images/back-menu.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	transition: all 400ms ease-in-out;
}

.scroll-menu{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: center;
}

.menu.full{
	display: none;
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;	 
}
.menu.full.open{
	display: flex;
 	z-index: 2;
}
.menu.middle{
	max-width: 100%;
}

.menu .menu-item a{
	display: flex;
	position: relative;
	text-align: center;
	font-size: 22px;
	color: #000000;
	letter-spacing: 0.3em;
	font-family: 'Quicksand Book';
}

.menu .menu-item a:first-child{
	margin-top: 0px;
}
.menu .menu-item a.active{
	font-family: 'Quicksand';
	color: #ffd200;
	font-weight: bold !important;
}

.menu .menu-item a.active:after{
	content: '';
	height: 3px;
	width: calc( ((80vw / 2 ) - 100% ) / 2 );
	background-color: #ffd200;
	position: absolute;
	top: 50%;
	right: calc( (((90vw / 2 ) - 100% ) / 2  ) * -1);
}
.menu a.privacy{
	text-decoration: none;
	font-size: 13px !important;
	color: black !important;
	margin-top: 10px;
	margin-bottom: 10px;
}
body.home a.active:before, body.home a.active:after{
	display: none !important;
	opacity: 0 !important;
	z-index: -2;
}

body:not(.home) .menu{
	width: 45%;
	z-index: 1;
	transition: all 400ms ease-in-out;
}
body:not(.home) .menu:not(.open){
	display: flex;
}
@media all and (max-width: 1024px) {
	.menu, body:not(.home) .menu {
		width: 100% !important;
		position: fixed;
		top: 100px !important;
		bottom: 0px;
	}

	.menu a.active:before, .menu a.active:after{
		display: none !important;
		opacity: 0 !important;
		z-index: -2;
	}
	.menu.full.open{
		overflow: scroll;
	}

	.menu:not(.open){
		display: none !important;
	}
	.scroll-menu{
		overflow: scroll;
		justify-content: flex-start !important;
	}
	.menu a{
		font-size: 18px !important;
	}
	.menu .menu-item a{
		font-size: 18px !important;
	}

	.menu-item{
		margin-bottom: 30px;
	}
	.menu-item.active .menu-item-close button{
		display: none !important;
	}
}

.menu-item{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	margin-bottom: 55px;
}

.menu-item .menu-item-close{
	position: absolute;
	right: -15px;
}

.menu-item .menu-item-close button{
	display: none;
	width: 30px;
	height: 30px;
	background-color: var(--accent-color);
	border-radius: 100%;
	border: 0px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}
body.home .menu-item-close button{
	display: none !important; 
}
.menu-item.active .menu-item-close button{
	display: flex;
	cursor: pointer;
}

@media (device-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
	.menu-item{
		max-width: 80% !important;
		margin-bottom: 55px !important;
	}
	.menu-item a{
		font-size: 18px !important;
	}
	.menu-item:first-child{
		margin-top: 30px !important;
	}
}